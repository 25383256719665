

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'  
import { notify } from "@kyvg/vue3-notification";
import {useRouter, useRoute} from 'vue-router' 


export default {
    name: 'privacy',
    components: { 
    },
    
    setup() {

        const store = useStore()  
        const locale = computed(() => store.state.translation.locale) 
        const route = useRoute()

        const data = reactive({ 
            loading: false,   
            inputValue: '',
            transaction_id:'',
        })


        onMounted(async () => {

            data.loading = true  ;
 
            let url = window.location.href; 
            let parts = url.split('/'); 
            let lastPart = parts.pop();  

            let transaction_id = lastPart && typeof(lastPart) != 'undefined'?lastPart : ''

            console.log("transaction_id========",transaction_id)

            if (isNaN(parseInt(transaction_id)))
            {
             data.inputValue = ''
             data.transaction_id = ''
            }
            else
            {
              data.transaction_id = transaction_id
              data.inputValue = locale.value.payment_success5+ ' : ' + transaction_id
            }
            //console.log("onMounted windows page is 1====",route.query) 
            
            data.loading = false
        })

       const copyToClipboard=()=>{
      // Use the Clipboard API
      navigator.clipboard.writeText(data.transaction_id)
        .then(() => {
          //this.copySuccess = 'Copied to clipboard!';
          console.log(' copying text success : ',data.transaction_id);
          notify({text: locale.value.payment_success6, type:'success'})
          setTimeout(() =>  {/* this.copySuccess = '' */}, 2000); // Clear the message after 2 seconds
        })
        .catch(err => {
          console.error('Error copying text: ', err);
          notify({text: locale.value.payment_success7, type:'error'})
          //this.copySuccess = 'Failed to copy';
        });
    }
 
        return { 
            data,
            locale, 
            copyToClipboard
        }
    }
}
