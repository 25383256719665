<template>
  <ul class="nav nav-pills side-nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
    <!-- back to here to activate dashboard image -->
   <!--  <div class="hide-on-mobile">
      <img src="@/assets/logo" alt="Agents Dashboard Logo" class="p-3 w-100 top-logo-bg">
    </div> -->

    <!-- <li class="nav-item-title"> -->
    <li class="w-100" @click="redirect('/dashboard')">
      <a href="#" class="nav-link px-0 align-middle  nav-link-title"><i class="fs-4 bi-speedometer2"></i>
        <span class="ms-1 d-none d-sm-inline">{{
          locale.dashboard
        }}</span></a>
    </li>

  
<!-- old style start from here  ==================================================================================================================== -->
 
<li class="mb-1 nav-item-title" v-if="checkAccess('manage_servers')">
        <a
          href="#servers"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        >
        <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"> {{ locale.manage }} {{ locale.servers }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a>
        
        <ul class="collapse nav flex-column ms-5" id="servers" data-bs-parent="#menu"> 
            <li  class="w-100" @click="redirect('/servers/list')">
              <a href="#" class="nav-link px-0">{{ locale.servers }} </a>
            </li>
            <li  class="w-100" @click="redirect('/servers/areas')">
              <a href="#" class="nav-link px-0">{{ locale.servers }} {{ locale.areas }}</a>
            </li>
            <li  class="w-100" @click="redirect('/servers/types')">
              <a href="#" class="nav-link px-0">{{ locale.servers }} {{ locale.types }}</a>
            </li>
            <li  class="w-100" @click="redirect('/servers/lists')">
              <a href="#" class="nav-link px-0">{{ locale.servers }} {{ locale.main }} {{ locale.lists }}</a>
            </li>
            <li  class="w-100" @click="redirect('/servers/list-information')">
              <a href="#" class="nav-link px-0">{{ locale.lists }} {{ locale.information }}</a>
            </li>
          </ul> 
      </li>


      <li class="mb-1 nav-item-title" v-if="checkAccess('manage_package')">
        <a
          href="#packages"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        >
          
           <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"> {{ locale.manage_package }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a> 
          <ul class="collapse nav flex-column ms-5" id="packages" data-bs-parent="#menu">
            <!-- 
            <li  class="w-100" @click="redirect('/package')">
              <a href="#" class="nav-link px-0">{{ locale.manage_timed_package }}  </a>
            </li> 
          -->
            <li  class="w-100" @click="redirect('/package_traffic')">
              <a href="#" class="nav-link px-0">{{ locale.manage_traffic_package }}  </a>
            </li>
            <li  class="w-100" @click="redirect('/package/categories')">
              <a href="#" class="nav-link px-0">{{ locale.packages }} {{ locale.lists }}  </a>
            </li> 
          </ul> 
      </li> 
      
      
      <li class="mb-1 nav-item-title" v-if="checkAccess('manage_package')">
        <a
          href="#coins"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        >
          
           <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"> {{ locale.manage_coins }}  {{ locale.plans }} </span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a> 
          <ul class="collapse nav flex-column ms-5" id="coins" data-bs-parent="#menu"> 
            <li  class="w-100" @click="redirect('/coins/add')">
              <a href="#" class="nav-link px-0">{{ locale.new }}  {{ locale.coins }} {{ locale.plan }}   </a>
            </li>  
            <li  class="w-100" @click="redirect('/coins')">
              <a href="#" class="nav-link px-0"> {{ locale.coins }} {{ locale.plans }}  </a>
            </li>  
            <li  class="w-100" @click="redirect('/coins/increase')">
              <a href="#" class="nav-link px-0">{{ locale.increase }}/{{ locale.decrease }} {{ locale.user }} {{ locale.coins }}   </a>
            </li> 
            <li  class="w-100" @click="redirect('/coins/balance')">
              <a href="#" class="nav-link px-0">  {{ locale.user }} {{ locale.coins }} {{ locale.balance }}  </a>
            </li> 
          </ul> 
      </li>
 
      
      <!-- back to here to add  v-if="checkAccess('manage_messages')" -->
      <li class="mb-1 nav-item-title" >
        <a
          href="#messages"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        >
          
          <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"> {{ locale.manage }} {{ locale.messages }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a>
         
        <ul class="collapse nav flex-column ms-5" id="messages" data-bs-parent="#menu">
            <li  class="w-100" @click="redirect('/messages/send')">
              <a href="#" class="nav-link px-0">{{ locale.send }} {{ locale.new }}{{ locale.message }}</a>
            </li>
             <li  class="w-100" @click="redirect('/messages')">
              <a href="#" class="nav-link px-0">{{ locale.messages }} </a>
            </li>
            <li  class="w-100" @click="redirect('/messages/categories')">
              <a href="#" class="nav-link px-0">{{ locale.messages }} {{ locale.categories }} </a>
            </li>
           <!-- <li  class="w-100" @click="redirect('/servers/lists')">
              <a href="#" class="nav-link px-0">{{ locale.servers }} {{ locale.main }} {{ locale.lists }}</a>
            </li> -->
          </ul> 
      </li>


    <!-- <li class="mb-1 nav-item-title" v-if="checkAccess('manage_reports')">
        <a
          href="#reports"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        >
          
           <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"> {{ locale.manage }} {{ locale.reports }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a>
         
          <ul class="collapse nav flex-column ms-5" id="reports" data-bs-parent="#menu">
            <li  class="w-100" @click="redirect('/reports/hourly')">
              <a href="#" class="nav-link px-0">{{ locale.hourly }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/monthly')">
              <a href="#" class="nav-link px-0">{{ locale.monthly }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/users/registered')">
              <a href="#" class="nav-link px-0">{{ locale.registered }} {{ locale.users }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/users/paying')">
              <a href="#" class="nav-link px-0">{{ locale.paying }} {{ locale.users }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/users/paying/first/time')">
              <a href="#" class="nav-link px-0">{{ locale.paying }} {{ locale.users }} {{ locale.first }}
                {{ locale.time }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/payments/renewals')">
              <a href="#" class="nav-link px-0">{{ locale.payment }} {{ locale.renewals }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/downloads/stats')">
              <a href="#" class="nav-link px-0">{{ locale.users }} {{ locale.download }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/users/onlin/vpn')">
              <a href="#" class="nav-link px-0">{{ locale.users }} {{ locale.online }} {{ locale.vpn }}</a>
            </li>
            <li  class="w-100" @click="redirect('/reports/users/watchAds')">
              <a href="#" class="nav-link px-0">{{ locale.user_watch_ads }}</a>
            </li>
             <li  class="w-100" @click="redirect('/reports/users/expired-soon')">
              <a href="#" class="nav-link px-0">{{ locale.users}} {{locale.expired_soon}}</a>
            </li>
          </ul> 
      </li>
    -->
     <li class="mb-1 nav-item-title" v-if="checkAccess('manage_users')">
        <a
          href="#users"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse" 
        >
         
           <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">  {{ locale.manage }} {{ locale.users }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a>
         
          <ul class="collapse nav flex-column ms-5" id="users" data-bs-parent="#menu">
            <li  class="w-100" @click="redirect('/users/list')">
              <a href="#" class="nav-link px-0">{{ locale.view }} {{ locale.users }} {{ locale.list }}</a>
            </li>
            
            <li  class="w-100" @click="redirect('/users/packages')">
              <a href="#" class="nav-link px-0">{{ locale.users }} {{ locale.packages }}</a>
            </li>
            
            <!-- <li  class="w-100" @click="redirect('/users/devices')">
              <a href="#" class="nav-link px-0">{{ locale.users }} {{ locale.devices }}</a>
            </li>
            <li  class="w-100" @click="redirect('/users/earn-points')">
              <a href="#" class="nav-link px-0"> {{ locale.user }} {{ locale.earn_points }}</a>
            </li>
            <li  class="w-100" @click="redirect('/users/redeem-points')">
              <a href="#" class="nav-link px-0"> {{ locale.user }} {{ locale.redeem_points }}</a>
            </li> -->
          </ul> 
      </li>  

      <li class="w-100 px-2" @click="redirect('/orders')"  v-if="checkAccess('manage_orders')">
      <a href="#" class="nav-link px-0 align-middle  nav-link-title"><i class="fs-4 bi-person-fill-gear"></i>
        <span class="ms-1 d-none d-sm-inline">{{ locale.manage }} {{ locale.orders }}</span></a>
    </li>
      

       
      <!-- <li class="mb-1 nav-item-title" v-if="checkAccess('manage_gift_code_builder')">
        <a
          href="#gift-code-builder"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        >
          
          <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline"> {{ locale.gift_code_builder }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a>
         
          <ul class="collapse nav flex-column ms-5" id="gift-code-builder" data-bs-parent="#menu">
            <li  class="w-100" @click="redirect('/gift-code-builder/batch')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.batch }}</a>
            </li>
            <li  class="w-100" @click="redirect('/gift-code-builder/gift-code')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.gift_codes }}</a>
            </li>
          </ul> 
      </li>

      <li class="mb-1 nav-item-title" v-if="checkAccess('manage_ads')">
        <a
          href="#ads"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        > 
           <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">  {{locale.manage}} {{ locale.ads }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a>
         
          <ul class="collapse nav flex-column ms-5" id="ads" data-bs-parent="#menu">
            <li  class="w-100" @click="redirect('/ads/ad-source')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.ad_source }}</a>
            </li>
             <li  class="w-100" @click="redirect('/ads/ad-source-code')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.ad_source_code }}</a>
            </li>
          </ul> 
      </li>

    -->

    <li class="mb-1 nav-item-title " v-if="checkAccess('manage_settings')">

           <a href="#manage_settings" data-bs-toggle="collapse" class="nav-link px-0 align-middle  nav-link-title">
              <i class="fs-4 bi-gear"></i> <span class="ms-1 d-none d-sm-inline"> {{ locale.general }} {{ locale.settings }}</span>
               <i class="fs-6 bi-chevron-compact-down alignRight"></i>
           </a>

        <ul class="collapse  nav flex-column ms-5" id="manage_settings" data-bs-parent="#menu">
          <li class="w-100" @click="redirect('/payment-methods')" v-if="checkAccess('manage_payment_methods')">
            <a href="#" class="nav-link px-0">{{ locale.payment }} {{ locale.methods }}</a>
          </li>
          <li class="w-100" @click="redirect('/settings/currency')">
              <a href="#" class="nav-link px-0">{{ locale.manage_currency }}</a>
          </li>
          <li  class="w-100" @click="redirect('/clients/announcements')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.announcements }}</a>
          </li>
          <li  class="w-100" @click="redirect('/clients/url-config-country')">
              <a href="#" class="nav-link px-0">{{ locale.clients }} {{ locale.country }} {{ locale.config }}</a>
          </li>
          <li  class="w-100" @click="redirect('/clients/url-config')">
              <a href="#" class="nav-link px-0">{{ locale.clients }} {{ locale.configuration }}</a>
          </li>
          <!-- <li  class="w-100" @click="redirect('/clients/clients-config')">
              <a href="#" class="nav-link px-0">{{ locale.clients }} {{ locale.config }}</a>
          </li> -->
          <li  class="w-100" @click="redirect('/settings/restricted-area')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.restricted }} {{ locale.area }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/whitelist')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.whitelist }}</a>
            </li>
          <li class="w-100" @click="redirect('/settings/faq')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.faq }}</a>
          </li>
          <li>
           <hr class="dropdown-divider">
          </li> 

        </ul>
     </li>

    <!--

      <li class="mb-1 nav-item-title" v-if="checkAccess('manage_clients')">
        <a
          href="#clients"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        > 
           <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">     {{ locale.manage }} {{ locale.clients }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a> 
          <ul class="collapse nav flex-column ms-5" id="clients" data-bs-parent="#menu">
            <li  class="w-100" @click="redirect('/clients/list')">
              <a href="#" class="nav-link px-0">{{ locale.clients }} {{ locale.list }}</a>
            </li>
            <li  class="w-100" @click="redirect('/clients/clients-config')">
              <a href="#" class="nav-link px-0">{{ locale.clients }} {{ locale.config }}</a>
            </li>
            <li  class="w-100" @click="redirect('/clients/announcements')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.announcements }}</a>
            </li>
            <li  class="w-100" @click="redirect('/clients/push-notification')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.push_notification }}</a>
            </li>
            <li  class="w-100" @click="redirect('/clients/url-config')">
              <a href="#" class="nav-link px-0">{{ locale.clients }} {{ locale.url_config }}</a>
            </li>
            <li  class="w-100" @click="redirect('/clients/url-config-country')">
              <a href="#" class="nav-link px-0">{{ locale.clients }} {{ locale.url_config }} {{ locale.country }}</a>
            </li>
          </ul> 
      </li>

      <li class="mb-1 nav-item-title" v-if="checkAccess('manage_settings')">
        <a
          href="#settings"
          class="nav-link px-0 align-middle  nav-link-title"
          data-bs-toggle="collapse"
          
        > 
          <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">      {{ locale.settings }}</span>
          
          <i class="fs-6 bi-chevron-compact-down alignRight"></i>
        </a> 
          <ul class="collapse nav flex-column ms-5" id="settings" data-bs-parent="#menu">
            <li  class="w-100" @click="redirect('/settings/currency')">
              <a href="#" class="nav-link px-0">{{
                locale.manage_currency
              }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/languages')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.languages }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/groups')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.groups }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/channels')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.channels }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/shortcuts')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.shortcuts }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/restricted-area')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.restricted }}
                {{ locale.area }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/whitelist')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.whitelist }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/points')">
              <a href="#" class="nav-link px-0">{{ locale.points }} {{ locale.settings }}</a>
            </li>

            <li  class="w-100" @click="redirect('/settings/domains')">
              <a href="#" class="nav-link px-0">{{ locale.manage }} {{ locale.domains }}</a>
            </li>
 

            <li class="border-top"></li>
            <li  class="w-100" @click="redirect('/settings/admin-users')">
              <a href="#" class="nav-link px-0">{{
                locale.manage_admin_users
              }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/roles')">
              <a href="#" class="nav-link px-0">{{
                locale.manage_roles
              }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/assign-permissions')">
              <a href="#" class="nav-link px-0">{{
                locale.assign_permissions
              }}</a>
            </li>
            <li  class="w-100" @click="redirect('/settings/permissions')">
              <a href="#" class="nav-link px-0">{{
                locale.manage_permissions
              }}</a>
            </li>
            <li  class="w-100" @click="redirect('/admin-operation-logs')">
              <a href="#" class="nav-link px-0">{{
                locale.admin_operation_logs
              }}</a>
            </li>
          </ul> 
      </li>
      <li class="border-top my-3"></li>

      <li
        class="mb-1 nav-item-title"
         class="w-100" @click="redirect('/blog')"
        v-if="checkAccess('manage_blog')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.blog }}</a
        >
      </li>
      <li
        class="mb-1 nav-item-title"
         class="w-100" @click="redirect('/category')"
        v-if="checkAccess('manage_blog')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.category }}</a
        >
      </li>
       

      <li
        class="mb-1 nav-item-title"
         class="w-100" @click="redirect('/tutorial')"
        v-if="checkAccess('manage_tutorial')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.tutorial }}</a
        >
      </li>

      <li class="border-top my-3" v-if="checkAccess('manage_ticket')"></li>

      <li
        class="mb-1 nav-item-title"
         class="w-100" @click="redirect('/ticket')"
        v-if="checkAccess('manage_ticket')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.ticket }}</a
        >
      </li>
      <li
        class="mb-1 nav-item-title"
         class="w-100" @click="redirect('/bug-reported')"
        v-if="checkAccess('manage_ticket')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.bugs }}</a
        >
      </li>
      <li
        class="mb-1 nav-item-title"
         class="w-100" @click="redirect('/error-reported')"
        v-if="checkAccess('manage_ticket')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.clients_error }}</a
        >
      </li>
 
      -->
      <li class="mb-1 nav-item-title " v-if="checkAccess('manage_settings')">

<a href="#manage_settings2" data-bs-toggle="collapse" class="nav-link px-0 align-middle  nav-link-title">
  <i class="fs-4 bi-gear"></i> <span class="ms-1 d-none d-sm-inline">{{ locale.admin }} {{ locale.settings }}</span>
  <i class="fs-6 bi-chevron-compact-down alignRight"></i>
</a>

<ul class="collapse  nav flex-column ms-5" id="manage_settings2" data-bs-parent="#menu">


  <li class="w-100" @click="redirect('/settings/admin-users')">
    <a href="#" class="nav-link px-0">{{
      locale.manage_admin_users
    }}</a>
  </li>
  <li class="w-100" @click="redirect('/settings/roles')">
    <a href="#" class="nav-link px-0">{{
      locale.manage_roles
    }}</a>
  </li>
  <li class="w-100" @click="redirect('/settings/permissions')">
    <a href="#" class="nav-link px-0">{{
      locale.manage_permissions
    }}</a>
  </li>
  <li class="w-100" @click="redirect('/settings/assign-permissions')">
    <a href="#" class="nav-link px-0">{{
      locale.assign_permissions
    }}</a>
  </li>
</ul>

</li> 
      <li>
          <hr class="dropdown-divider">
        </li>
    <li class="w-100 px-2" @click="redirect('/account')">
      <a href="#" class="nav-link px-0 align-middle  nav-link-title"><i class="fs-4 bi-person-fill-gear"></i>
        <span class="ms-1 d-none d-sm-inline">{{
          locale.account
        }}</span></a>
    </li>

    <li class="w-100 px-2" @click="redirect('/settings/change/locale')">
      <a href="#" class="nav-link px-0 align-middle  nav-link-title">
        <i class="fs-4 bi-globe"></i>
        <span class="ms-1 d-none d-sm-inline">{{ locale.change_locale }}</span></a>
    </li>

    <li class="w-100 px-2" @click="logout">
      <a href="#" class="nav-link px-0 align-middle  nav-link-title">
        <i class="fs-4 bi-sign-turn-slight-left"></i>
        <span class="ms-1 d-none d-sm-inline">{{ locale.logout }}</span>
      </a>
    </li> 

  </ul>
</template>
<script>
import { onMounted, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import {checkAccess} from '@/utils/utils'

export default {
  name: "SideNav",

  setup() {
    const router = useRouter();
    const store = useStore();
    const auth = computed(() => store.state.authenticated);
    const authUser = computed(() => store.state.authUser);
    const locale = computed(() => store.state.translation.locale);

    const data = reactive({
      selectedMenu: "",
      isAgent:false,
    });

    onMounted(async () => {
      if (!auth.value) {
        await router.push("/login");
      }

      // check if agent
      if (authUser.value.role_id == 17) {
          data.isAgent= true;
        }
      //let lang = 'cn'//authUser.value.preferred_language ? authUser.value.preferred_language : "en";
      //store.dispatch(`translation/getLangs`, { userLang: lang });
    });


    const redirect = (page) => {

      router.push(`${page}`);
    };

    const logout = async () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      await store.dispatch("setAuth", false).then(async () => {
        await store.dispatch("setAdmin", false).then(async () => {
          await store.dispatch("setAuthUser", null) 

                                     
                                });
                            })
      // await store.dispatch("setAuthUser", null);
      //await router.push("/login");
    };

    const checkAccess = (action) => {
      try {
        if (authUser.value.role_id == 1) {
          return true;
        }
        let findIndex = authUser.value.rolesArr.findIndex(
          (obj) => obj.permission_key === action
        );
        if (findIndex == 0 || (findIndex && findIndex != "-1")) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    };

    return {
      locale,
      auth,
      data,
      redirect,
      logout,
      checkAccess,
    };
  },
};
</script>
 