<template>
    <div class="container-fluid">
        <div class="row no-gutter">

            <div class="col-md-6 d-none d-md-flex bg-image" v-if="!data.show_change_pass_form">
                                <!-- <div class="text-center  mb-5">
                                     <img src="@/assets/logo" alt="Agents Dashboard Logo" class="p-3 w-100 ">
                                </div> -->
            </div>

<div class="col-md-6 bg-light">
                <div class="login d-flex align-items-center py-5">


                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <!-- back to here and activat image for login -->
                                <!-- <div class="  mb-5">
                                     <img src="@/assets/logo" alt="Agents Dashboard Logo" class="p-3 w-100 ">
                                </div> -->
                                <!-- <h3 class="display-4 mb-5 fw-semibold hide-on-mobile">{{ locale.product_name }} </h3> -->
                                <form @submit.prevent="submit" v-if="!data.show_change_pass_form">
                                    <div class="form-floating mt-3">
                                        <input v-model="data.username" type="text"
                                            class="form-control rounded-pill border-0 shadow-sm px-4"
                                            placeholder="Username">
                                        <label>{{ locale.login_username }}</label>
                                    </div>
                                    <div class="form-floating mt-3">
                                        <input v-model="data.password" type="password"
                                            class="form-control rounded-pill border-0 shadow-sm px-4"
                                            placeholder="Password">
                                        <label>{{ locale.login_password }}</label>
                                    </div>
                                    <div class="form-check  my-4">
                                        <input id="rempass" v-model="data.rememberPass" type="checkbox"  class="form-check-input" @click="setRememberPassword()" />
                                        <label for="rempass" class="form-check-label">Remember password</label>
                                    </div>
                                    <div class="d-grid gap-2 mt-2">
                                        <button class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm"
                                            type="submit">{{ locale.login }}<span v-if="data.submitBtn"
                                                class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span></button>
                                    </div>
                                </form>

                                <form autocomplete="off" v-if="data.show_change_pass_form">

                                    <h1>{{ locale.change_password_heading }}</h1>

                                    <label class="form-label">{{ locale.current }} {{ locale.password }}</label>
                                    <div class="input-group mb-3">
                                        <input v-model="data.user_update_pass.current_password" type="password"
                                            id="current_password" class="form-control" autocomplete="off"
                                            required="required">
                                        <span class="input-group-text cursor-pointer "
                                            @click="switchVisibility('current_password')">
                                            <div class="eye"></div>
                                        </span>
                                    </div>
                                    <label class="form-label">{{ locale.new }} {{ locale.password }}</label>
                                    <div class="input-group mb-3">
                                        <input v-model="data.user_update_pass.new_password" type="password"
                                            id="new_password" class="form-control" autocomplete="off">
                                        <span class="input-group-text cursor-pointer "
                                            @click="switchVisibility('new_password')">
                                            <div class="eye"></div>
                                        </span>
                                    </div>
                                    <div class="mb-3">
                                        <button @click="submitAccount" class="w-100 btn btn-lg btn-primary mt-3"
                                            type="button">{{ locale.submit }}<span v-if="data.submitAccountBtn"
                                                class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {onMounted, reactive, computed} from 'vue'
import {useRouter} from 'vue-router'
import { useStore } from '@/store';
import { notify } from "@kyvg/vue3-notification";
import {apiHost} from '@/config/config'
import { sha256 } from 'js-sha256'
import { encrypt, decrypt ,toUtcDateTime } from "@/utils/utils";
import "@/assets/css/login.css";

export default{
    name:'Login',
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed(() => store.state.authUser)
        const locale = computed(() => store.state.translation.locale);
        const data = reactive({
            endpoint_account: '/account',
            username:'',
            password:'',
            rememberPass:localStorage.getItem("rem_pass_status") ?? "false",
            client_ip:'',
            submitBtn: false,
            user_detail: {},
            submitAccountBtn: false,
            show_change_pass_form:false,
            user_update_pass:{
                current_password: '',
                new_password: '',
                email: '',
                phone: '',
            }
        })
        onMounted( async() =>{
            //console.log("login auth.value ************************* ",auth.value) 
            if (auth.value)
                router.push(`/`);
 
            // check remember password
            //console.log("rem_pass===============",localStorage.getItem("rem_pass_status"));
            
                if (localStorage.getItem("rem_pass_status")=="true")
            {data.username = localStorage.getItem("remUsername")


               // try {
                data.password = decrypt(localStorage.getItem("remPass"));
            //} catch (error) {

             //   console.log("rem_pass===============error",error );
            //}
            
             //   console.log("rem_pass===============",data.username,data.password);
            } 
            else
            {
              //  console.log("rem_pass===============xxxxxx" );
                data.username = '';
                data.password = '';
            }
            
            

           // console.log("rem_pass===============$$$$$$" );
            let lang = 'cn'
            await store.dispatch(`translation/getLangs`,{userLang:lang})

            // back and activate 
            /* await fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    data.client_ip = ip
                    //console.log("client ip detail in veu.js",ip)
                }).catch(error => {
                    console.log("client ip err", error)
                }) */
        })

        const setRememberPassword=()=>{
            let checkedStatus = document.querySelector('#rempass').checked;
            //console.log("remPass checked====",typeof(checkedStatus))
            
            localStorage.setItem("rem_pass_status",checkedStatus)
            data.rememberPass = checkedStatus; 
            if (!checkedStatus)
            {
                localStorage.setItem("remUsername",'')
                localStorage.setItem("remPass",'')
            }
            //console.log("data.rememberPass ====",data.rememberPass)
        }

        const submit = async () => {


if (!data.username) {
    notify({ text: store.state.translation.locale.enter_username, type: 'error' })
    return
}
if (!data.password) {
    notify({ text: store.state.translation.locale.enter_password, type: 'error' })
    return
}


data.submitBtn = true

  
   await loginAPI(data.username, data.password)
 
}


const loginAPI = (userName, passWord ) => { 

 

// TYPE=1 Normal login , TYPE=2 query login
console.log("loginAPI======", userName, passWord)
let _body = {
        username: userName,
        password: sha256(sha256(passWord + sha256(passWord + passWord))),
                    
    }
     fetch(apiHost + "/login?client_ip=" + data.client_ip, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(_body)
    }).then(async response => {
        data.submitBtn = false
        const resData = await response.json()
        console.log('resData=============',resData)
        if (!response.ok) {
            let error = (resData && resData.message) || response.status
            if (resData.errors) {
                error = ''
                resData.errors.forEach(element => { error += element.msg + "<br>" })
            }
            notify({ text: error, type: 'error' })
        } else {
            
            let user = resData.data
            if (resData.data.user_needs_to_change_pass) {
                data.show_change_pass_form = true
                data.user_detail = user
                data.user_update_pass.email = data.user_detail.email
                data.user_update_pass.phone = data.user_detail.phone
                //await store.dispatch("setAuthUser", user)
            } else {
                //console.log("login auth.value ************************* ",user , passWord) 
            //console.log("before dispatch======1111111111111111111",typeof(localStorage.getItem("rem_pass_status")))

            if (!auth.value) {
                
                
                await store.dispatch("setAuthUser", user).then(async () => {
                    //console.log("before dispatch======2222222222222222222")
                    
                    if (localStorage.getItem("rem_pass_status") == "true") {
                            await localStorage.setItem("remUsername", user.username)
                            await localStorage.setItem("remPass", encrypt(passWord))
                          //  console.log("password saved to localstorage ======")
                        }
                    await store.dispatch("setAuth", true).then(async () => {
                       // console.log("before dispatch======33333333333333")

                      
                        

                        await store.dispatch(`translation/getLangs`,{userLang:user.preferred_language}).then(async () => {
                  
                           // console.log("after dispatch======1111111111111111111")
                        notify({
                            text: resData.message,
                            type: 'success'
                        });
                       
                            await router.push('/')
                         
                })

                       

                    });
                })

            }



        }
    }
}).catch(error => {
    data.submitBtn = false
    notify({ title: "Error", text: error, type: 'error' })
})

}

        const submitAccount = async() => {
            if(!data.user_update_pass.current_password){
                notify({text: 'Current password required to make any change in your account.', type:'error'})
                //router.push(`/login`)
                return
            }
            if(!data.user_update_pass.new_password){
                notify({text: 'New password required.', type:'error'})
                //router.push(`/login`)
                return
            }
            if(data.user_update_pass.current_password ===data.user_update_pass.new_password){
                notify({text: 'New password should not be same as current password.', type:'error'})
                //router.push(`/login`)
                return
            }
            //This one is test

            data.submitAccountBtn = true
            let _body = {
                current_password:  sha256(sha256(data.user_update_pass.current_password+sha256(data.user_update_pass.current_password+data.user_update_pass.current_password))) ,
                new_password: sha256(sha256(data.user_update_pass.new_password+sha256(data.user_update_pass.new_password+data.user_update_pass.new_password)))
            }
            await fetch(apiHost+"/account?client_ip="+data.client_ip,{
                method:'POST',
                headers: {
                    'Authorization': data.user_detail.accessToken,
                    'Content-type': 'application/json; charset=UTF-8',
                },
                body:JSON.stringify(_body)
            }).then(async response => {
                data.submitBtn = false
                const resData = await response.json()
                console.log('resData',resData)
                if (!response.ok) {
                    let error = (resData && resData.message) || response.status
                    if(resData.errors){
                        error = ''
                        resData.errors.forEach(element => { error += element.msg +"<br>"})
                    }
                    notify({text: error, type:'error'})
                if(resData.status==401)router.push(`/login`)
                }else{
                    await store.dispatch("setAuth", true)
                    await store.dispatch("setAuthUser", data.user_detail)
                    notify({text: resData.message,type:'success'})
                    data.user_update_pass = {}
                    await router.push('/')
                }
            }).catch(error => {
                data.submitAccountBtn = false
                notify({title: "Error",text: error,type:'error'})
                router.push(`/login`)
            })
        }

        const switchVisibility = (field_id) => {
            const passwordField = document.querySelector('#'+field_id)
            passwordField.getAttribute('type')==='password' ? passwordField.setAttribute('type','text') : passwordField.setAttribute('type','password')
        }

        return {
            locale,
            data,
            submit,
            submitAccount,
            switchVisibility,
            setRememberPassword
        }
    }
}
</script>

<style scoped>

</style>
