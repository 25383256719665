

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'  


export default {
    name: 'terms',
    components: { 
    },
    
    setup() {

        const store = useStore()  
        const locale = computed(() => store.state.translation.locale)  

        const data = reactive({ 
            loading: false,   
        }) 

        onMounted(async () => {

            //data.loading = true   
            let supportScript = document.createElement('script')
            var protocol = window.location.protocol;
            var host = window.location.host;
            var url = protocol + "//" + host;
            supportScript.setAttribute('src', url +'/js/support.js')
            document.head.appendChild(supportScript) 
            //data.loading = false
        })
 
        return { 
            data,
            locale, 
        }
    }
}
