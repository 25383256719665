
import * as CryptoJS from "crypto-js";

export function checkAccessSinglePage(action: string, authUser: any){
    try{
        if(authUser.role_id == 1){
            return true
        }
        const findIndex = authUser.rolesArr.findIndex(obj => obj.permission_key === action)
        if(findIndex == 0 || (findIndex && findIndex!='-1')){
            return true
        }else{
            return false
        }
    }catch(err){
        return false
    }
}

export function generateSlug(str: string){
    try{
        return str
        .trim()                      // remove whitespaces at the start and end of string
        .toLowerCase()              
        .replace(/^-+/g, "")         // remove one or more dash at the start of the string
        .replace(/[^\w-]+/g, "-")    // convert any on-alphanumeric character to a dash
        .replace(/-+/g, "-")         // convert consecutive dashes to singuar one
        .replace(/-+$/g, "")
    }catch(err){
        console.log("err")
    }
}
export function validURL(str: string){
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  // Capitalize the first letter of each word in a string
export function capitalizeFirstOfEveryWord(str) {
    const arr = str.split(" ");
    //loop through each element of the array and capitalize the first letter.
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
  
    let result = ''
    for (let i = 0; i < arr.length; i++) {
      result = result +' '+ arr[i];
    }
  
    return result
  }

  
export function encrypt(src) {
    const passphrase = "ALAA573";
    return CryptoJS.AES.encrypt(src, passphrase).toString();
  }
  
  export function decrypt(src) {
    const passphrase = "ALAA573";
    const bytes = CryptoJS.AES.decrypt(src, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
 
  
  export function toUtcDateTime(d){
    return new Date(d).toISOString().replace('T', ' ').split(".")[0]
  }
  
  
