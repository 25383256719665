

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import { updateDownloadReport, getRootDomain } from "@/api/api"

export default {
    name: 'ios',
    components: {
        Breadcrumb 
    },

    setup() {

        const store = useStore()
        const locale = computed(() => store.state.translation.locale)
        const route = useRoute()

        const data = reactive({
            loading: false,
            page: 1,
            hover:false,
            isCollapsed1:false,
            isCollapsed2:false,
            isCollapsed3:false,
            images:[
                '/images/tutorials/ios/1.svg',
                '/images/tutorials/ios/2.svg',
                '/images/tutorials/ios/3.svg',
                '/images/tutorials/ios/4.svg',
                '/images/tutorials/ios/5.svg', 
                '/images/tutorials/ios/6.svg',
                '/images/tutorials/ios/7.svg',
                '/images/tutorials/ios/8.svg',
                '/images/tutorials/ios/9.svg',
                '/images/tutorials/ios/10.svg', 
                '/images/tutorials/ios/11.svg',
                '/images/tutorials/ios/12.svg',
                '/images/tutorials/ios/13.svg', 
            ]
        })


        onMounted(async () => {

            data.loading = true

            data.page = parseInt(route.query.page as string) || 1


            data.loading = false
        })

        
        const handleMouseOver=()=>{
         console.log("handleMouseOver===")
         data.hover = true
        }

        const handleMouseLeave=()=>{
            console.log("handleMouseLeave===")
            data.hover = false
       }
 
       const toggleCollapse=(id)=>{
        if (id == 1)
        {
          data.isCollapsed1 = !data.isCollapsed1;   
          data.isCollapsed2 = false;
          data.isCollapsed3 = false;
        } 
        else
        if (id == 2)
        { 
          data.isCollapsed1 = false;   
          data.isCollapsed2 = !data.isCollapsed2;
          data.isCollapsed3 = false;
        }
        else
        if (id == 3)
        { 
          data.isCollapsed1 = false;   
          data.isCollapsed2 = false;
          data.isCollapsed3 = !data.isCollapsed3;
        }
         
       }

        const openImageInNewTab=(fullImageSrc: string)=>{
          window.open(fullImageSrc, '_blank');
        }

        const openURL = (url:string) => {
            
            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.target = "_blank";
            link.style.display = 'none'; // Ensure it's hidden 
            // Use a unique ID to avoid conflicts
            link.id = 'auto-download-ios-link'; // Use a unique ID to avoid conflicts  
            // Append to the body
            document.body.appendChild(link);
            // Trigger the download
            link.click();
            // Remove the link
            document.body.removeChild(link);

            // save to download report 
            updateDownloadReport(window.location.href, 'ios', getRootDomain(window.location.host));

        }


        return {
            data,
            locale,
            openURL,
            openImageInNewTab,
            handleMouseOver,
            handleMouseLeave,
            toggleCollapse
        }
    }
}
