import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container px-0" }
const _hoisted_2 = { class: "container-fluid py-3 page-main-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col text-center page-main-title" }
const _hoisted_5 = { class: "white-text" }
const _hoisted_6 = { class: "col-md-9 col-lg-10 px-md-4" }
const _hoisted_7 = { class: "container py-3" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "my-2" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString($setup.locale.footer14), 1)
        ])
      ])
    ]),
    _createElementVNode("main", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", {
              class: "hc3 mt-5 mb-3 bold",
              innerHTML: $setup.locale.terms1
            }, null, 8, _hoisted_10),
            _createElementVNode("span", {
              class: "hc6 lh",
              innerHTML: $setup.locale.terms2
            }, null, 8, _hoisted_11),
            _createElementVNode("span", {
              class: "hc6 lh",
              innerHTML: $setup.locale.terms3
            }, null, 8, _hoisted_12)
          ]),
          _createElementVNode("p", {
            class: "hc3 mt-5 mb-3 bold",
            innerHTML: $setup.locale.terms4
          }, null, 8, _hoisted_13),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms5
          }, null, 8, _hoisted_14),
          _createElementVNode("p", {
            class: "hc3 mt-5 mb-3 bold",
            innerHTML: $setup.locale.terms6
          }, null, 8, _hoisted_15),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms7
          }, null, 8, _hoisted_16),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms8
          }, null, 8, _hoisted_17),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms9
          }, null, 8, _hoisted_18),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms10
          }, null, 8, _hoisted_19),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms11
          }, null, 8, _hoisted_20),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms12
          }, null, 8, _hoisted_21),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms13
          }, null, 8, _hoisted_22),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms14
          }, null, 8, _hoisted_23),
          _createElementVNode("p", {
            class: "hc3 mt-5 mb-3 bold",
            innerHTML: $setup.locale.terms15
          }, null, 8, _hoisted_24),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms16
          }, null, 8, _hoisted_25),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms17
          }, null, 8, _hoisted_26),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms18
          }, null, 8, _hoisted_27),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms19
          }, null, 8, _hoisted_28),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms20
          }, null, 8, _hoisted_29),
          _createElementVNode("p", {
            class: "hc3 mt-5 mb-3 bold",
            innerHTML: $setup.locale.terms21
          }, null, 8, _hoisted_30),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms22
          }, null, 8, _hoisted_31),
          _createElementVNode("p", {
            class: "hc3 mt-5 mb-3 bold",
            innerHTML: $setup.locale.terms23
          }, null, 8, _hoisted_32),
          _createElementVNode("span", {
            class: "hc6 lh",
            innerHTML: $setup.locale.terms24
          }, null, 8, _hoisted_33)
        ])
      ])
    ])
  ]))
}