import { getTranslationByChannelName } from '@/config/channels'

export default {
    namespaced: true,
    state: {
        selectedLang: '',
        locale: {}
    },
    mutations: {
        updateLang(state, data) {
            state.locale = data
        }
    },
    actions: {
        async getLangs({ commit, state }, payload) {
            let langSelected = 'cn'
            if(payload.userLang){
                langSelected = payload.userLang.toLowerCase()
            }
            state.selectedLang = langSelected




           
        try {
            // Dynamically load the appropriate language file based on langSelected
            const translationModule = await import(`@/config/`+getTranslationByChannelName());
            
            // Commit the loaded translations to the Vuex store
            commit('updateLang', translationModule.loadLang(langSelected));
          } catch (error) {
            console.error(`Error loading translation for language: ${langSelected}`, error);
            
            // Optionally, load a fallback language in case of an error (e.g., English)
            const fallbackLang = await import(`@/config/translation_jc`);
            commit('updateLang', fallbackLang.loadLang(langSelected));
          }


           // commit('updateLang', loadLang(langSelected)) 
        }
    }
}
 
