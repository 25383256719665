<template>

    <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb">
            
            <li v-if="!is_home" @click="$redirect('/tutorials?p=2')" class="breadcrumb-item"><a href="javascript:void(0)">{{locale.support}}</a></li>
            
            <li v-if="item_one_title" class="breadcrumb-item" :class="item_one_active ? 'active' : ''" aria-current="page">
              <a v-if="item_one_url" @click="$redirect(item_one_url)" href="javascript:void(0)">{{item_one_title}}</a>
              <a v-else>{{item_one_title}}</a>
            </li>
           <li v-if="item_two_title" class="breadcrumb-item" :class="item_two_active ? 'active' : ''" aria-current="page">
              <a v-if="item_two_url" @click="$redirect(item_two_url)" href="javascript:void(0)">{{item_two_title}}</a>
              <a v-else>{{item_two_title}}</a>
            </li>

            <li class="ps-2"><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></li>

        </ol>
    </nav>

   
</template>

<script>
import { computed } from 'vue'
import {useStore} from 'vuex'

export default {
    name: "Breadcrumb",
    props: {
        is_home: {type: Boolean, default:false},//dashboard
        item_one_title: {type: String},
        item_one_active : {type: Boolean},
        item_one_url: {type: String},

        item_two_title: {type: String},
        item_two_active : {type: Boolean},
        item_two_url: {type: String},

        loading : {type: Boolean},
        
    },

    setup(){
        const store = useStore()
        const locale = computed(() => store.state.translation.locale)

        return {
           locale,
        }
    }
};
</script>
