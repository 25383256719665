import { getChannelName } from "@/config/channels";
import { downloadServerLink } from "@/config/config";

export default {
  namespaced: true,
  state: {
    current_channel: "jc", 
    downloads  : {
    downloadWindowsClientLink:"", 
    downloadAndroidClientLink:"", 
    downloadIosClientLink:"", 
    downloadLinuxClientLink:""
   }
  },
  mutations: {
    updateChannel(state, data) {
      state.current_channel = data; 
     
      console.log("updateChannel=================",data)
      switch (data) {
        case "jc": { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_juechenjsq.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_juechenjsq.apk";
          state.downloads.downloadIosClientLink =   "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
          return;
        }
        case "tf1": { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_tf1.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_tf1.apk";
          state.downloads.downloadIosClientLink =   "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
          return;
        }
        case "tf2": { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_tf2.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_tf2.apk";
          state.downloads.downloadIosClientLink =   "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
          return;
        }
        case "tf3": { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_tf3.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_tf3.apk";
          state.downloads.downloadIosClientLink =   "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
          return;
        }
        case "tf4": { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_tf4.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_tf4.apk";
          state.downloads.downloadIosClientLink =   "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
          return;
        }
        case "tf5": { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_tf5.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_tf5.apk";
          state.downloads.downloadIosClientLink =   "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
          return;
        }
        case "email": { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_email.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_email.apk";
          state.downloads.downloadIosClientLink =  "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
          return;
        }
        default: { 
          state.downloads.downloadWindowsClientLink = downloadServerLink + "jcjsq_live_24.10.31.7_juechenjsq.exe";
          state.downloads.downloadAndroidClientLink = downloadServerLink + "JC_v1.0.18_juechenjsq.apk";
          state.downloads.downloadIosClientLink =  "/tutorials/ios?p=2";
          state.downloads.downloadLinuxClientLink = downloadServerLink + "";
        }
      }
    },
  },
  actions: {
    async setChannel({ commit, state }, payload) { 
      
      const ChannelName = getChannelName();
      try {
        console.log("setChannel=================",ChannelName)
        // Commit the loaded translations to the Vuex store
        commit("updateChannel", ChannelName);
      } catch (error) {
        console.error( `Error loading translation for language: ${ChannelName}`, error );
        commit("updateChannel", "jc");
      }
    },
  },
};
