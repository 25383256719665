

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex' 
import {useRouter, useRoute} from 'vue-router' 


export default {
    name: 'privacy',
    components: { 
    },
    
    setup() {

        const store = useStore()  
        const locale = computed(() => store.state.translation.locale) 
        const route = useRoute()

        const data = reactive({ 
            loading: false,  
             
        })


        onMounted(async () => {

            data.loading = true  
             
            //console.log("onMounted windows page is 1====",route.query) 
            
            data.loading = false
        })
 
        return { 
            data,
            locale, 
        }
    }
}
