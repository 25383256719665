

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex' 
import {useRouter, useRoute} from 'vue-router'
import Breadcrumb from "@/components/layout/Breadcrumb.vue"


export default {
    name: 'mac',
    components: {
        Breadcrumb
    },
    
    setup() {

        const store = useStore()  
        const locale = computed(() => store.state.translation.locale) 
        const route = useRoute()

        const data = reactive({ 
            loading: false,  
            page:1,
            hover:false,
        })
 

        onMounted(async () => {

            data.loading = true  
            
            data.page=  parseInt(route.query.page as string)  || 1 
             
            
            data.loading = false
        })

        const handleMouseOver=()=>{
         console.log("handleMouseOver===")
         data.hover = true
        }

        const handleMouseLeave=()=>{
            console.log("handleMouseLeave===")
            data.hover = false
       }
 

        return { 
            data,
            locale,
            handleMouseOver,
            handleMouseLeave,
        }
    }
}
