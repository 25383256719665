export const websiteTitle = "JC VPN";

import { capitalizeFirstOfEveryWord } from '@/utils/utils'

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Login from "@/components/login/Login.vue" 
import Dashboard from "@/components/dashboard/Index.vue"
import Account from "@/components/Account.vue"

// Main website pages - Visitors pages
import Home from "@/components/home/Home.vue"
import Tutorials from "@/components/vpn_setup/tutorials.vue"
import WindowsDoc from "@/components/vpn_setup/windows.vue"
import AndroidDoc from "@/components/vpn_setup/android.vue"
import MacDoc from "@/components/vpn_setup/mac.vue"
import IosDoc from "@/components/vpn_setup/ios.vue"

import Privacy from "@/components/pages/privacy.vue"
import Terms from "@/components/pages/terms.vue"
import Support from "@/components/pages/support.vue"
import PaymentSuccess from "@/components/pages/payment-success.vue"


const routes: Array<RouteRecordRaw> = [ 
  { path: '/dashboard', component: Dashboard ,meta: {title: "dashboard"}},
  { path: '/login', component: Login ,meta: {title: "login"}},
  { path: '/login', component: Login },
  { path: '/account', component: Account }, 

  { path: '/tutorials', component: Tutorials   ,meta: {title: "Tutorials 下载问题"}}, //windows
  { path: '/tutorials/windows', component: WindowsDoc   ,meta: {title: "Windows下载失败"}}, //windows
  { path: '/tutorials/android', component: AndroidDoc   ,meta: {title: "Android下载失败"}}, //android
  { path: '/tutorials/mac', component: MacDoc   ,meta: {title: "Mac下载失败"}}, //mac
  { path: '/tutorials/ios', component: IosDoc   ,meta: {title: "IOS下载失败"}}, //ios

  { path: '/privacy_policy', component: Privacy   ,meta: {title: "Privacy Policy 隐私政策"}},
  { path: '/terms', component: Terms   ,meta: {title: "Terms & Conditions 服务条款"}},
  { path: '/support', component: Support   ,meta: {title: "Support 在线咨询"}},
  { path: '/payment-success/:id', component: PaymentSuccess   ,meta: {title: "Payment Success 充值绝尘币"}}, 
  { path: '/payment-success', component: PaymentSuccess   ,meta: {title: "Payment Success 充值绝尘币"}},



  { path: '/', component: Home   ,meta: {title: "首页"}}, //Home
]

const router = createRouter({ history: createWebHistory(process.env.BASE_URL), routes })

router.beforeEach((to, from, next) => { 
  document.title = capitalizeFirstOfEveryWord(to.meta.title ? `${websiteTitle} - `+to.meta.title as string : `${websiteTitle} `);
  next() 
 })

export default router
